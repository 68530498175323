import React from 'react'
import './Paper.css'
const Paper = () => {
  return (
    <div class="paper-container">
        <div className="paper">

            <div class="paper-content">
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Delectus, blanditiis? Dignissimos, soluta. Praesentium repudiandae neque rerum ad, iste adipisci doloribus sunt. Ullam, quam molestias. Quibusdam molestiae amet soluta earum, laborum placeat, enim consequuntur exercitationem consectetur numquam eligendi voluptate et eveniet ex corrupti minima debitis itaque perspiciatis suscipit molestias. Totam molestiae consequuntur sed laboriosam magnam maiores quisquam ab vitae ipsum suscipit? Laborum maiores corporis doloremque neque! Quis cumque, veniam inventore autem deleniti quod nostrum repellendus reprehenderit expedita animi magnam accusamus culpa quo. Fugit ipsam possimus quaerat facere culpa, voluptates tempora. Nam at recusandae incidunt culpa voluptatibus ad nemo reiciendis eveniet! Dolorem.</p>
            </div>
        </div>

    </div>
    
  )
}

export default Paper