export const Media = [
    {type: 'image', url: require('../../assets/foto-1.jpg')},
    {type: 'image', url: require('../../assets/foto-2.jpg')},
    {type: 'image', url: require('../../assets/foto-3.jpg')},
    {type: 'image', url: require('../../assets/foto-4.jpg')},
    {type: 'image', url: require('../../assets/foto-5.jpg')},
    {type: 'image', url: require('../../assets/foto-6.jpg')},
    {type: 'image', url: require('../../assets/foto-7.jpg')},
    {type: 'image', url: require('../../assets/foto-8.jpg')},
    {type: 'image', url: require('../../assets/foto-9.jpg')},
    {type: 'image', url: require('../../assets/foto-11.jpg')},



]